import _ from 'i18n';
import { User } from 'store/Auth/types';
import { Language } from 'store/types';
const { protocol } = window.location;

export const APP_ENV = process.env.REACT_APP_APP_ENV;
export const LARAVEL_SESSION_COOKIE =
  process.env.REACT_APP_LARAVEL_SESSION_COOKIE;

export const HTTP_SCHEME = protocol;
export const S3_FOLDER_URL = `${protocol}//${process.env.REACT_APP_S3_FOLDER_URL}`;
export const S3_FOLDER_URL_PROD = `${protocol}//s3.tamtam.pro/prod`;
export const S3_FOLDER_URL_WITHOUT_ENV = `${protocol}//s3.tamtam.pro`;
export const S3_FOLDER_AWS_URL = `${protocol}//${process.env.REACT_APP_S3_FOLDER_AWS_URL}`;
export const S3_FOLDER_AWS_URL_ALT = `${protocol}//${process.env.REACT_APP_S3_FOLDER_AWS_URL_ALTERNATIVE}`;
export const S3_FOLDER_AWS_URL_WITHOUT_ENV = `${protocol}//tamtam.s3.eu-west-1.amazonaws.com`;

export const VIMEO_CLIENT_ID = process.env.REACT_APP_VIMEO_CLIENT_ID;
export const VIMEO_CLIENT_SECRET = process.env.REACT_APP_VIMEO_CLIENT_SECRET;
export const VIMEO_ACCESS_TOKEN = process.env.REACT_APP_VIMEO_ACCESS_TOKEN;
export const VIMEO_FOLDER = process.env.REACT_APP_VIMEO_FOLDER;

export const TTP_API_URL = `${protocol}//${process.env.REACT_APP_TTP_API_URL}`;
export const TTP_HOME_URL = `${protocol}//${process.env.REACT_APP_TTP_HOME_URL}`;
export const TTP_HELP_URL = `${protocol}//${process.env.REACT_APP_TTP_HELP_URL}`;

export const TTP_BLOG_URL = `${protocol}//${process.env.REACT_APP_TTP_BLOG_URL}`;
export const TTP_FFF_BLOG_URL = `${protocol}//${process.env.REACT_APP_TTP_FFF_BLOG_URL}`;
export const TTP_FR_CONGRESS_BLOG_PAGE =
  process.env.REACT_APP_FR_CONGRESS_BLOG_PAGE;
export const TTP_NL_CONGRESS_BLOG_PAGE =
  process.env.REACT_APP_NL_CONGRESS_BLOG_PAGE;

export const TTP_FLUX_URL = `${protocol}//${process.env.REACT_APP_TTP_FLUX_URL}`;
export const TTP_EMAILING_URL = `${protocol}//${process.env.REACT_APP_TTP_EMAILING_URL}`;
export const TTP_EVENT_URL = `${protocol}//${process.env.REACT_APP_TTP_EVENT_URL}`;
export const TTP_TALK_URL = `${protocol}//${process.env.REACT_APP_TTP_TALK_URL}`;
export const TTP_SURVEY_URL = `${protocol}//${process.env.REACT_APP_TTP_SURVEY_URL}`;
export const TTP_MEDIA_URL = `${protocol}//${process.env.REACT_APP_TTP_MEDIA_URL}`;
export const TTP_PAYMENT_URL = `${protocol}//${process.env.REACT_APP_TTP_PAYMENT_URL}`;
export const TTP_FORUM_URL = `${protocol}//${process.env.REACT_APP_TTP_FORUM_URL}`;
export const TTP_WEBTOOL_URL = `${protocol}//${process.env.REACT_APP_TTP_WEBTOOL_URL}`;
export const TTP_PORTAL_EVENT_URL = `${protocol}//${process.env.REACT_APP_TTP_EVENT_PORTAL_URL}`;
export const TTP_WEBINAR_CHAT_URL = `https://${process.env.REACT_APP_TTP_WEBINAR_CHAT_URL}`;
export const TTP_PORTAL_OFFF_COURSE_URL = `${protocol}//${process.env.REACT_APP_TTP_EVENT_PORTAL_OFFF_COURSE_URL}`;

export const GOOGLE_MAP_API_KEY = 'AIzaSyAiOtVCQorixsHMcyagZDJVDGhdbbfANl4';

export const COPYRIGHT_YEAR = new Date().getFullYear();

export const CLIENT_CREDENTIAL = {
  grant_type: 'client_credentials',
  client_id: '10012',
  client_secret: 'EmailingSecretS#K$',
  scope: 'ttp',
};

export const TTP_LOGO_URL = '/img/logo.png';
export const TTP_LOGO_EVENT_URL = '/img/logo-event.png';
export const TTP_LOGO_BLOG_URL = 'https://s3.tamtam.pro/v2/apps/blog.png';
export const TTP_LOGO_OFFFCOURSE_URL = '/img/logo-oFFFcourse.svg';
export const TTP_LOGO_OFFFCOURSE_INVERTED_URL =
  '/img/logo-oFFFcourse-inverted.svg';
export const TTP_EMBLEM_OFFFCOURSE_URL = '/logo512-offfcourse.png';

export const EVENT_COUPON_KEY = `event_coupon_${APP_ENV}`;
export const AUTH_COOKIE_NAME = `ttp_auth_${APP_ENV}`;
export const AUTH_LOW_SECURITY_COOKIE_NAME = `ttp_auth_ls_${APP_ENV}`;
export const AUTH_OFFFCOURSE_COOKIE_NAME = `ttp_auth_offfcourse_${APP_ENV}`;
export const AUTH_LOW_SECURITY_OFFFCOURSE_COOKIE_NAME = `ttp_auth_offfcourse_ls_${APP_ENV}`;

export const TOKEN_LS_SCOPE = 'event_ls';
export const FFF_ID = 9;
export const COMMUNITY_NAME_MAX_LENGTH = 20;
export const COMMUNITY_DEFAULT_LOGO_URL = '/img/community-default-logo.png';
export const OFFFCOURSE_URL = 'https://offfcourse.be';
export const OFFFCOURSE_CONGRESS_URL = `${OFFFCOURSE_URL}/event/1874/registration`;
export const OFFFCOURSE_CONGRESS_IMG_URL = `${S3_FOLDER_URL}${'/events-folder/client9/event311/_model/html/img/bannerFr_2022_02_09_13_29_31-original.png'}`;
export const PREMIUM_OFFER_OFFFCOURSE_URL = `${OFFFCOURSE_URL}/cycle/56/reception`;
export const OUR_OFFERS_OFFFCOURSE_URL = `${OFFFCOURSE_URL}/plans`;

export const appMetadata = {
  event: {
    title: 'Tamtam Pro International - Event',
    description:
      'Event allows you to organize events of various sizes, from the simplest to the most sophisticated.',
    iconsUrl: {
      favicon: '/favicon-event.ico',
      logo192: '/logo192-event.ico',
      logo512: '/logo512-event.ico',
    },
  },
  offfcourse: {
    title: 'oFFFCourse',
    description:
      'oFFFCourse allows you to organize events of various sizes, from the simplest to the most sophisticated.',
    iconsUrl: {
      favicon: '/favicon-offfcourse.ico',
      logo192: '/logo192-offfcourse.ico',
      logo512: '/logo512-offfcourse.ico',
    },
  },
};

export const MENUS = {
  GUEST: [],
  READER: ['COMMUNITIES', 'TYPES', 'ALBUMS'],
  MEMBER: ['COMMUNITIES', 'TYPES', 'ALBUMS'],
  ADMIN: ['COMMUNITIES', 'TYPES', 'ALBUMS'],
};

export const SOCIAL_NETWORKS_HOSTS = {
  FACEBOOK: 'https://www.facebook.com',
  TWITTER: 'https://www.twitter.com',
  LINKEDIN: 'https://www.linkedin.com',
};

export const BUBBLE_EVENTS = {
  DELETE_SELECTED_MEDIAS: 'DELETE_SELECTED_MEDIAS',
  ADD_TO_ALBUM: 'ADD_TO_ALBUM',
};

export const SETTINGS_USERS_PAGE_LIMIT = 9;

export const LANGUAGES: Language[] = ['fr', 'nl', 'en'];

export const MAP_LANGUAGES: {
  [key: string]: any;
} = {
  fr: _('french'),
  nl: _('dutch'),
  en: _('english'),
};

/**
 * https://stackoverflow.com/a/19999868/12046639
 */
export const IS_IE = (function () {
  let isIE = false;

  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');

  // eslint-disable-next-line no-useless-escape
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    // If Internet Explorer, return version number
    isIE = true;
  } else {
    // If another browser, return 0
  }

  return isIE;
})();

export const COUNTRIES = [
  ...['BE', 'FR', 'NL', 'GB', 'LU', 'DE', 'IE', 'IT', 'DK', 'FI', 'ES', 'GI'],
  ...['LI', 'LU', 'MC', 'NO', 'PT', 'SM', 'SE', 'CH', 'VA', 'AD', 'AT'],
];

export const LEGAL_FORMS = [
  ...['ACP', 'ADF', 'AFJ', 'AFRAIS', 'AISBL', 'ASBL', 'ASS COM', 'ASS INT'],
  ...['ASS MOM', 'EUP', 'FDP PJ', 'FOND', 'FONDPRIV', 'FSE', 'FUP', 'GEIE'],
  ...['GIE', 'GIE FS', 'ISBL', 'MUTU DP', 'SA', 'SA FS', 'SAGR', 'SASPJ'],
  ...['SC', 'SCA', 'SCA FS', 'SCE', 'SCIV SA', 'SCIV SAGR', 'SCIV SCA'],
  ...['SCIV SCRI', 'SCIV SCRIS', 'SCIV SCRL', 'SCIV SCS', 'SCIV SNC'],
  ...['SCIV SPRL', 'SCRI', 'SCRI FS', 'SCRIS', 'SCRIS CP', 'SCRIS FS', 'SCRL'],
  ...['SCRL CP', 'SCRL FS', 'SCS', 'SCS FS', 'SDC', 'SE', 'SF', 'SI', 'SM'],
  ...['SNC', 'SNC FS', 'SOC MUTU', 'SPRL', 'SPRL FS', 'SPRLU', 'SPRLU FS'],
  ...['SYNDIC', 'UP', 'I', 'SRL', 'S.COM', 'SCISR', 'SCRLP', 'ES', 'BI'],
  ...['RESP.TVA', 'NC'],
];

export const VAT = [
  'VAT_SYSTEM_VAT',
  'VAT_SYSTEM_NO_VAT',
  'VAT_SYSTEM_PARTIAL_VAT',
  'VAT_SYSTEM_MIXED_VAT',
];

export enum PREMIUM_DEFAULT_PRICES {
  PRICE = 1090,
  ORIGINAL_PRICE = 6880,
  MONTHLY_PRICE = 99,
}

export enum SEASON_DEFAULT_PRICES {
  PRICE = 449,
  ORIGINAL_PRICE = 980,
}

export const PREMIUM_STATUS_FETCH_DELAY = 5000;
export const IS_PREMIUM_SHARED = true; // TODO : to improve

export const URL_HASH_KEY = 'Y3YZVA6uSLVqJhAWHdsb9CsGgcBXIhmY';

// Same breakpoints used with foundation
export enum Breakpoints {
  SMALL = 0,
  MEDIUM = 640,
  LARGE = 1024,
  X_LARGE = 1200,
  XX_LARGE = 1440,
}

export const USER_GENERIC_FIELDS: (keyof User)[] = [
  'firstName',
  'lastName',
  'mainEmail',
  'language',
  'gender',
  'mainPhone',
];

export const AUTOLOGIN_PARAM_KEYS = [
  'id',
  'email',
  'time',
  'token',
  'expiresIn',
  'createdAt',
  'key',
];

// Exports
export * from './Images';
export * from './EventConfig';
